import checking from "./images/checking.svg"
import servers from "./images/servers-checking.gif"

const CheckingConnection = () => {
  return (
    <div id="check-connection" style={{width: window.screen.width}}>
      <h4 id="checking-connection-header">
      <img src={servers} alt="Servers gif" className="servers-icon" /><br/>
      <img src={checking} alt="Checking loading" id="checking-connection-icon" />
       Vérification des serveurs...
      </h4>
    </div>
  )
}

export default CheckingConnection
