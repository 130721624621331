import { PiTestTubeFill } from "react-icons/pi"
// import { RiRobot3Fill } from "react-icons/ri"
import { FaCircleArrowUp } from "react-icons/fa6"
import React, { useEffect, useState } from 'react'
import AdviceModal from './AdviceModal';
import timer from './images/timer.svg'
import search from './images/search.svg'
import searching from './images/searching.gif'
import globe from './images/globe.svg'
import { io } from 'socket.io-client';
import bot from "./images/bot2.png"

const MainBody = ({modelServerUrl, dbServerUrl}) => {
 const [hasValidContent, setHasValidContent] = useState(false)
 const [isProcessing, setIsProcessing] = useState(false)
 const [messages, setMessages] = useState([])
 const [currentQuestion, setCurrentQuestion] = useState(undefined)
 const [currentResponse, setCurrentResponse] = useState(undefined)
 let isShiftPressed = false
 const socket = io(modelServerUrl)
socket.on("browsing", (query) => {
  document.getElementById("generating").setAttribute('hidden', 'hidden')
  document.getElementById("browsing").removeAttribute('hidden')
  document.getElementById("search-terms").innerText = '"' + query.query.trim() + '"'
})
socket.on("generating", () => {
  document.getElementById("browsing").setAttribute('hidden', 'hidden')
  document.getElementById("generating").removeAttribute('hidden')
} )
socket.on("response", (response) => {
  document.getElementById("generating").setAttribute('hidden', 'hidden')
  setIsProcessing(false)
  let mainResponse
  if(response.browsed === false){
    mainResponse = {answer: response.answer, browsed: false, sources: false}
  }else{
    if(response.sources === false){
      mainResponse = {answer: response.answer, browsed: true, sources: false}
    }else{
      const sources = response.sources.replaceAll(/\[SOURCES\]|\[\/SOURCES\]/g, "").split(' | ')
      mainResponse = {answer: response.answer, browsed: true, sources: sources}
    }
  }
  setMessages((prev) => [...prev, {role: "assistant", value: mainResponse.answer, browsed: mainResponse.browsed, sources: mainResponse.sources}])
  setCurrentResponse({answer: mainResponse.answer, browsed: mainResponse.browsed, sources: mainResponse.sources})
  setTimeout(() => document.getElementById("adviceModalButton").click(), 300)
})

const formatText = (text) => {
  return text.split('\\n').map((line, index) => (
    <React.Fragment key={index}>
      {line.split('\\t').map((segment, i) => (
        <React.Fragment key={i}>
          {i > 0 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          {segment}
        </React.Fragment>
      ))}
      <br />
    </React.Fragment>
  ));
}
 const process = async () => {
   const question = document.getElementById("question")
   const value = question.value
   setMessages((prev) => [...prev, {role: "user", value: value}])
   document.getElementById("generating").removeAttribute('hidden')
   question.value = null
   document.getElementById('contentLength').innerText = 0
   socket.emit("question", {question: value})
   setIsProcessing(true)
   setHasValidContent(false)
   setCurrentQuestion(value)
   question.focus()
 }

 useEffect(() => {
   const block = document.getElementById("messages")
   window.scrollTo({top: block.scrollHeight, behavior: "smooth"})
 }, [messages])

  return (
    <div>
      {
        messages.length > 0 && <div id="mainIconDiv">
          <img src={bot} alt="bot head" id="bot-head"/>
          <b>JARVIS - AI ASSISTANT</b>
        </div>
      }
      <div id="messages">
        {
          messages.length > 0 ? messages.map((message, key) => {
            return (
                <div key={key} className="singleMessage">
                  {
                    message.role === "user" ? 
                      <div className="message pull-right">
                        {message.value}
                      </div> : <div className="message pull-left">
                            {message.browsed === true ? <div id='browsed'>
                              <img src={globe} alt="Globe" className="pull-right doing globe" width={20} />
                                                        <small><b>[WEB CONSULTÉ]</b></small>
                                                        </div> : <></>}
                            {formatText(message.value)}
                            {
                              message.sources !== false ?
                                <div className='sources'>
                                  <br/>
                                  <h6><b><img src={search} alt="Search loop" className="pull-right doing" width={20} /> Sources consultées</b></h6>
                                  <ul>
                                    {
                                      message.sources.map((source, sourceKey) => {
                                        return <li key={sourceKey}><a href={source} rel="noreferrer" target='_blank' className='sourceLink'>{source}</a></li>
                                      })
                                    }
                                  </ul>
                                </div>
                              : <></>
                            }
                          </div>
                  }
                </div>
              )
          }) : <div style={{color: "white", textAlign: "center", margin: "auto"}}>
                <img src={bot} alt="bot welcome head"/>
                <h2>Bonjour, comment puis-je vous aider ?</h2>
          </div>
        }
        <div className="pull-left isLabel" id='browsing' hidden>
          <img src={searching} alt="Search loop" className="pull-right doing" width={25} /> recherche web pour <i><b id='search-terms'></b></i>         
        </div>
        <div className="pull-left isLabel" id='generating' hidden>
          <span id='generating-dot'></span> réponse...         
        </div>
        <div id="lastChild"></div>
      </div>
      <form onSubmit={(e) => e.preventDefault()} id="chatForm">
        <div id="chatBox">
          <textarea maxLength={250} placeholder="Type your message here" rows={1} id="question" name="question" type="text" className="form-controll form-control-md" 
            onKeyDown={(e) => {
              if(e.key.toLowerCase() === "shift"){
                isShiftPressed = true
              }
              if(e.key.toLowerCase() === "enter"){
                if(e.target.value.replaceAll(" ", "").length > 0
                    && e.target.value.replaceAll("\n", "").length > 0 
                    && !isShiftPressed){
                        if(!isProcessing){
                          e.preventDefault()
                          process()
                        }
                }
              }
            }}
            onKeyUp={(e) => {
              if(e.key.toLowerCase() === "shift"){
                isShiftPressed = false
              }
            }}
            onChange={(e) => {
              const escaped = e.target.value.replaceAll(' ', '')
              if(escaped.length > 0 && !hasValidContent){
                setHasValidContent(true)
              }else if(escaped.length === 0 && hasValidContent){
                setHasValidContent(false)
              }
              if(escaped.length > 0){
                document.getElementById('contentLength').innerText = e.target.value.length
              }else{
                document.getElementById('contentLength').innerText = 0
              }
            }} />
          <small className='d-none d-lg-inline-block'><span id='contentLength'>0</span>/250</small>
          {
            !isProcessing ? <FaCircleArrowUp role="button" style={{
              color: hasValidContent ? "white" : "rgb(204, 204, 204)",
              fontSize: "2em",
              cursor: hasValidContent ? "pointer" : "default"
            }} id="submitMessageButton" className="pull-right" onClick={async () => {
              if(hasValidContent && !isProcessing){
                process()
              }
            }}/> : <img src={timer} alt="Pending timer" id="pending-timer" className="pull-right" width={35} />
          }
        </div>
        <div id="advices">
          <div>
            <PiTestTubeFill size="1.5em" /> Cet assistant est en test, il peut souvent générer du contenu incorrect ou inaproprié. <br/>
          </div>
          <i><b><u>NOTE:</u></b> Cette version ne considère pas le contexte historique, ce qui veut dire que chaque question est traitée indépendamment des précédentes.</i>
        </div>
      </form>
      <button type="button" id="adviceModalButton" hidden data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>
      <AdviceModal question={currentQuestion} response={currentResponse} setQuestion={setCurrentQuestion} setResponse={setCurrentResponse} dbServerUrl={dbServerUrl}/>
    </div>
  )
}

export default MainBody
