import { LuServerOff } from "react-icons/lu";

const BadConnectionState = () => {
 return (
   <div id="bad-connection" style={{width: window.screen.width}}>
     <h4 id="bad-connection-header">
      <LuServerOff color="red" size="3em"/> <br/> <br/>
      Les serveurs sont actuellement indisponibles.
      <br/><br/>
      <small>Veuillez <a href="." id="reload-link">reéssayer</a>.</small>
     </h4>
   </div>
 )
}

export default BadConnectionState
