import axios from "axios"
import React from "react";

const AdviceModal = ({question, response, setQuestion, setResponse, model, dbServerUrl}) => {
  const formatText = (text) => {
    return text.split('\\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.split('\\t').map((segment, i) => (
          <React.Fragment key={i}>
            {i > 0 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            {segment}
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    ));
  }
  
  return (
    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <b><big>Question</big>: </b> {question}
            <br/><br/>
            <b><big>Réponse</big>: </b> {response !== undefined ? formatText(response.answer) : ""}
            <br/><br/>
            <div className="commonDiv">
              <input type="checkbox" id="isCorrect" defaultChecked name="isCorrect"/> <label className="label" htmlFor="isCorrect">Réponse correcte</label>
            </div>
            <div className="commonDiv">
              <label className="label" htmlFor="note" id="noteLabel">NOTE </label>
              <select name="note" id="note">
                <option value={-1}>(-1) : MAUVAISE</option>
                <option value={0}>(0) : NEUTRE</option>
                <option value={1}>(1) : BIEN</option>
                <option value={2}>(2) : TRES BIEN</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
          <button type="button" id="closeModal" hidden data-bs-dismiss="modal"></button>
            <button type="button" id="sendAdviceButton" className="btn btn-default" onClick={async () => {
              if(question !== undefined){
                document.getElementById("sendAdviceButton").setAttribute("disabled", "disabled")
                const isCorrect = document.getElementById("isCorrect").checked
                const note = document.getElementById("note").value
                const timestamp = new Date()
                await axios.post(`${dbServerUrl}/datas`, {question, response, isCorrect, note, timestamp})
                document.getElementById("closeModal").click()
                document.getElementById("sendAdviceButton").removeAttribute("disabled")
                setQuestion(undefined)
                setResponse(undefined)
              }
            }}>Envoyer</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdviceModal
