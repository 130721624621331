import { useState } from 'react';
import './App.css';
import axios from 'axios';
import CheckingConnection from './CheckingConnection';
import BadConnectionState from './BadConnectionState';
import MainBody from './MainBody';


function App() {
  const base = "https://jarvis.codingart.ma"
  const modelServerUrl = base + ":8888"
  const dbServerUrl = base + ":3000"
  const [serversAvailable, setServersAvailable] = useState(null)
  setTimeout(() => {
    checkConnection()
  }, 1000)
  const checkConnection = async () => {
    try {
      const modelServerResponse = await axios.get(`${modelServerUrl}/ack`, {timeout: 3000, insecureHTTPParser: false})
      const dbServerResponse = await axios.get(`${dbServerUrl}/datas?_page=1&_limit=1`, {timeout: 3000})
      if(modelServerResponse.data.ready === true
                  && dbServerResponse.status === 200){
        setServersAvailable(true)
      }else{
        setServersAvailable(false)
      }
    } catch (error) {
      setServersAvailable(false)
    }
  }
  return (
    <div id="App">
        {
          serversAvailable === null ? <CheckingConnection />
            : (
              serversAvailable ? <MainBody modelServerUrl={modelServerUrl} dbServerUrl={dbServerUrl}/>
                : <BadConnectionState />
            )
        }
    </div>
  );
}

export default App;
